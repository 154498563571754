import React from "react";
import "./footer.css";
// import darklogo from "../../assets/pav_logo_mob.png";

function FooterSection() {
  return (
    <footer class="bg-white">
      <div id="contact">
        <div className="google-map-code">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.131937678109!2d-0.19614858474461952!3d5.694069533827346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9daaae55ee07%3A0xeeec0e5967ce1ffb!2sF%26O%20Construction%20and%20Logistics%20Limited!5e0!3m2!1sen!2sgh!4v1661270085989!5m2!1sen!2sgh"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>

        <div
          style={{
            backgroundImage:
              "url(https://images.unsplash.com/photo-1534536281715-e28d76689b4d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
          }}
          className="bg-fixed bg-center bg-blend-darken"
        >
          <div className="bg-black bg-opacity-70">
            <div class="max-w-7xl  mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
              <div class="divide-y-2 divide-gray-200">
                <div class="lg:grid lg:grid-cols-3 lg:gap-8">
                  <h2 class="text-2xl font-extrabold text-white sm:text-3xl">
                    Get in touch
                  </h2>
                  <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                    <div>
                      <h3 class="text-lg leading-6 font-bold text-yellow-500">
                        Email Us
                      </h3>
                      <dl class="mt-2 text-base text-gray-200">
                        <div>
                          <dt class="sr-only">Email</dt>
                          <dd>
                            <a href="mailto:foconstruction.logistics@gmail.com">
                              foconstruction.logistics@gmail.com
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div>
                      <h3 class="text-lg leading-6 font-bold text-yellow-500">
                        Phone Numbers
                      </h3>
                      <dl class="mt-2 text-base text-gray-200">
                        <div>
                          {/* <dt class="sr-only">Post</dt> */}
                          {/* <dd>4</dd> */}
                        </div>
                        <div class="mt-1">
                          <dt class="sr-only">Call US</dt>
                          <dd>
                            <a href="tel:0303944160">0303944160</a>
                          </dd>
                          <dd>
                            <a href="tel:0203055655">0203055655</a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div>
                      <h3 class="text-lg leading-6 font-bold text-yellow-500">
                        Working Hours
                      </h3>
                      <dl class="mt-2 text-base text-gray-200">
                        <div>
                          {/* <dt class="sr-only">Post</dt> */}
                          {/* <dd>4</dd> */}
                        </div>
                        <div class="mt-1">
                          {/* <dt class="sr-only">Call US</dt> */}
                          <dd>Monday - Friday</dd>
                          <dd>8:00am – 5:00pm</dd>
                        </div>
                      </dl>
                    </div>
                    <div>
                      <h3 class="text-lg leading-6 font-bold text-yellow-500">
                        Postal Address
                      </h3>
                      <dl class="mt-2 text-base text-gray-200">
                        <div>
                          {/* <dt class="sr-only">Post</dt> */}
                          {/* <dd>4</dd> */}
                        </div>
                        <div class="mt-1">
                          {/* <dt class="sr-only">Call US</dt> */}
                          <dd>P.O Box GP2643</dd>
                          <dd>Accra</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
                  <h2 class="text-2xl font-extrabold text-white sm:text-3xl">
                    Locations
                  </h2>
                  <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                    <div>
                      <h3 class="text-lg leading-6 font-medium text-gray-100">
                        North Legon, Betty Villa
                      </h3>
                      <div class="mt-2 text-base text-gray-200">
                        <a
                          target="_blank"
                          href="https://goo.gl/maps/yzv451bEUCTAGv6M7"
                        >
                          Ghana Post: GE-166-8044
                        </a>
                        {/* <p class="mt-1">Los Angeles, CA 90210</p> */}
                      </div>
                    </div>

                    <div>
                      <h3 class="text-lg leading-6 font-medium text-gray-100">
                        Socials
                      </h3>
                      <div class="mt-2 text-base text-gray-200">
                        <div class="flex  space-x-6 md:order-2">
                          <a
                            target="_blank"
                            href="mailto:foconstruction.logistics@gmail.com"
                            class="text-yellow-400 hover:text-gray-500"
                          >
                            <span class="sr-only">Email</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-9 h-9"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                              />
                            </svg>
                          </a>
                          <a
                            target="_blank"
                            href="https://facebook.com/foconstructionandlogisticsltd"
                            class="text-blue-400 hover:text-gray-500"
                          >
                            <span class="sr-only">Facebook</span>
                            <svg
                              class="h-9 w-9"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </a>

                          <a
                            target="_blank"
                            href="https://instagram.com/foconstructionandlogisticsltd"
                            class="text-red-400 hover:text-gray-500"
                          >
                            <span class="sr-only">Instagram</span>
                            <svg
                              class="h-9 w-9"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </a>

                          <a
                            target="_blank"
                            href="https://twitter.com/foconsandlogltd"
                            class="text-blue-400 hover:text-gray-500"
                          >
                            <span class="sr-only">Twitter</span>
                            <svg
                              class="h-9 w-9"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                          <a
                            target="_blank"
                            href="https://wa.me/233203055655"
                            class="text-green-400 hover:text-gray-500"
                          >
                            <span class="sr-only">WhatsApp</span>
                            <img
                              style={{ width: 35, height: 35 }}
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/2044px-WhatsApp.svg.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      
        <div class="mt-8 md:mt-0 md:order-1">
          <p class="text-center text-base text-gray-400">
            &copy; {new Date().getFullYear()} Itmsgh, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
