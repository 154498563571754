import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cookiesConfig from "../../utils/cookies.config";
import Transitions from "../../utils/Transitions";
import logo from "../../assets/logo.jpg";

export default function AppHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <Fragment>
      <nav class="bg-white  sticky z-10 top-0 bg-opacity-75  backdrop-blur backdrop-filter">
        <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex px-2 lg:px-0">
              <div class="flex-shrink-0 flex items-center">
                <img
                  class="block lg:hidden h-8 w-auto"
                  src={logo}
                  alt="Workflow"
                />
                <img
                  class="hidden lg:block h-8 w-auto"
                  src={logo}
                  alt="Workflow"
                />
                <h1 className="block lg:hidden lg:block font-bold ml-2 text-2xl">
                  F&O
                </h1>
                <h1 className="hidden lg:block font-bold ml-2 text-2xl">
                  F&O Construction & Logistics Ltd.
                </h1>
              </div>
              <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
                <a
                  href="/"
                  class={`${
                    pathname == "/"
                      ? "border-red-500 border-b-2 text-gray-900"
                      : "text-gray-500"
                  }  inline-flex items-center px-1 pt-1  text-sm font-medium`}
                >
                  Home
                </a>
                <div class="relative z-10  bg-transparent">
                  <div class="max-w-7xl mx-auto flex px-2 py-6 sm:px-2 lg:px-2">
                    {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
                    <button
                      type="button"
                      onClick={() => {
                        setShowAboutMenu(!showAboutMenu);
                      }}
                      class={`  group  rounded-md inline-flex items-center text-base font-medium hover:text-gray-900`}
                      aria-expanded="false"
                    >
                      <span className="">About Us</span>
                      <svg
                        class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <a
                  href="/our-services"
                  class={`${
                    pathname.includes("services")
                      ? "border-red-500 border-b-2 text-gray-900"
                      : "text-gray-500"
                  }   hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1  text-sm font-medium`}
                >
                  Our Services
                </a>
                <a
                  href="/gallery"
                  class={`${
                    pathname.includes("gallery")
                      ? "border-red-500 border-b-2 text-gray-900"
                      : "text-gray-500"
                  }   hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1  text-sm font-medium`}
                >
                  Gallery
                </a>
                <a
                  href="#contact"
                  class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Contact Us
                </a>
              </div>
            </div>

            <div class="flex items-center lg:hidden">
              <button
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                aria-controls="mobile-menu"
                onClick={() => setShowMenu(!showMenu)}
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>

                <svg
                  class="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  class="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="hidden lg:ml-4 lg:flex lg:items-center">
              <div class="ml-4 relative flex-shrink-0">
                <div>
                  <button
                    type="button"
                    class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    id="user-menu-button"
                    aria-expanded="false"
                    onClick={() => setShowMenu(!showMenu)}
                    aria-haspopup="true"
                  >
                    <span class="sr-only">Open user menu</span>
                    {/* <img
                      class="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=ah3lxr8uqw&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    /> */}
                  </button>
                </div>

                {/* <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
                <Transitions
                  // show={showMenu}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                  >
                    <span
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-0"
                    >
                      Your Profile
                    </span>
                    <span
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-1"
                    >
                      Settings
                    </span>
                    <span
                      onClick={() => {
                        setShowMenu(false);
                        cookiesConfig.clearCipher();
                        push("/login");
                      }}
                      //   href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-2"
                    >
                      Sign out
                    </span>
                  </div>
                </Transitions>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Transitions
            show={showAboutMenu}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <div class="absolute bg-white  sticky z-10 top-0 bg-opacity-75  backdrop-blur backdrop-filter inset-x-0 transform shadow-lg">
              <div class="">
                <div class="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                  <a
                    href="/about-us"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <div class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-red-500 text-white sm:h-12 sm:w-12">
                          {/* <!-- Heroicon name: outline/chart-bar --> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-base font-medium text-gray-900">
                            Company Overview
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Get a better understanding of our Company
                          </p>
                        </div>
                        <p class="mt-2 text-sm font-medium text-red-600 lg:mt-4">
                          Learn more <span aria-hidden="true">&rarr;</span>
                        </p>
                      </div>
                    </div>
                  </a>

                  <a
                    href="/team"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <div class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-red-500 text-white sm:h-12 sm:w-12">
                          {/* <!-- Heroicon name: outline/cursor-click --> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-base font-medium text-gray-900">
                            Our Team
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Profile of our management team
                          </p>
                        </div>
                        <p class="mt-2 text-sm font-medium text-red-600 lg:mt-4">
                          Learn more <span aria-hidden="true">&rarr;</span>
                        </p>
                      </div>
                    </div>
                  </a>

                  <a
                    onClick={() => {
                      setShowAboutMenu(false);
                    }}
                    href="/about-us#values"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <div class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-red-500 text-white sm:h-12 sm:w-12">
                          {/* <!-- Heroicon name: outline/shield-check --> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.563 9.75a12.014 12.014 0 00-3.427 5.136L9 12.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-base font-medium text-gray-900">
                            Core Values
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Traits and Qualities that supports the company's
                            vision
                          </p>
                        </div>
                        <p class="mt-2 text-sm font-medium text-red-600 lg:mt-4">
                          Learn more <span aria-hidden="true">&rarr;</span>
                        </p>
                      </div>
                    </div>
                  </a>

                  <a
                    onClick={() => {
                      setShowAboutMenu(false);
                    }}
                    href="/about-us#mission"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <div class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-red-500 text-white sm:h-12 sm:w-12">
                          {/* <!-- Heroicon name: outline/view-grid --> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-base font-medium text-gray-900">
                            Mission & Vision
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            What we aspire to be
                          </p>
                        </div>
                        <p class="mt-2 text-sm font-medium text-red-600 lg:mt-4">
                          Learn more <span aria-hidden="true">&rarr;</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </Transitions>
        </div>

        <div class="lg:hidden" id="mobile-menu">
          <Transitions
            show={showMenu}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div>
              <div class="pt-2 pb-3 space-y-1">
                <a
                  href="#"
                  class="bg-red-50 border-red-500 text-red-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Home
                </a>
                <a
                  href="/about-us"
                  class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Company Overview
                </a>

                <a
                  href="/team"
                  class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Our Team
                </a>
                <a
                  href="/about-us#values"
                  class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Our Core Values
                </a>
                <a
                  href="/about-us#mission"
                  class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Mission & Vision
                </a>
                <a
                  href="/gallery"
                  class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Gallery
                </a>

                <a
                  href="#contact"
                  class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </Transitions>
        </div>
      </nav>
    </Fragment>
  );
}
