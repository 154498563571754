const { lazy } = require("react");

const Dashboard = lazy(() => import("../screens/Dashboard/index"));
const AboutUS = lazy(() => import("../screens/Dashboard/AboutUS"));
const Team = lazy(() => import("../screens/Dashboard/Team"));
const Gallery = lazy(() => import("../screens/Dashboard/Gallery"));
const OurServices = lazy(() => import("../screens/Dashboard/OurServices"));

const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/about-us",
    exact: true,
    component: AboutUS,
    name: "AboutUS",
  },
  {
    path: "/team",
    exact: true,
    component: Team,
    name: "Team",
  },
  {
    path: "/our-services",
    exact: true,
    component: OurServices,
    name: "OurServices",
  },
  {
    path: "/gallery",
    exact: true,
    component: Gallery,
    name: "Gallery",
  },
];

export default routes;
